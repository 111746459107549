exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-competitors-index-js": () => import("./../../../src/pages/competitors/index.js" /* webpackChunkName: "component---src-pages-competitors-index-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-events-index-js": () => import("./../../../src/pages/events/index.js" /* webpackChunkName: "component---src-pages-events-index-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-sponsors-index-js": () => import("./../../../src/pages/sponsors/index.js" /* webpackChunkName: "component---src-pages-sponsors-index-js" */),
  "component---src-templates-club-member-js": () => import("./../../../src/templates/ClubMember.js" /* webpackChunkName: "component---src-templates-club-member-js" */),
  "component---src-templates-competitor-js": () => import("./../../../src/templates/Competitor.js" /* webpackChunkName: "component---src-templates-competitor-js" */),
  "component---src-templates-event-js": () => import("./../../../src/templates/Event.js" /* webpackChunkName: "component---src-templates-event-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/NewsPost.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-sponsor-js": () => import("./../../../src/templates/Sponsor.js" /* webpackChunkName: "component---src-templates-sponsor-js" */)
}

